import React from 'react'
import styles from './Betting.module.css'
import Page from '../Layout/Page'

function Betting(props) {
  return (
    <Page>
      <section className={styles.intro}>
        <div className={styles.content}>
          <h1 className={styles.heading}>
            Betting på F1 och annan motorsport.
          </h1>
          <p className={styles.preamble}>
            Betting på motorsport har kommit att bli mycket populärt. Det finns
            många olika typer av spel från flera av de bästa spelbolagen. På den
            här sidan kommer vi dela opartisk information om odds, olika
            speltips, och spelbolag.
          </p>
        </div>
      </section>
      <div className={styles.wrapper}>
        <div className={styles.flexContent}>
          <div className={styles.container}>
            <section className={styles.section}>
              <h2 className={styles.sectionHeading}>
                Hur man bettar på motorsport
              </h2>
              <p>
                Det finns olika sätt du kan vinna pengar på motorsport betting.
                Beroende på vilken racingserie du önskar att betta på finns det
                olika typer av spel. Oftast, men inte alltid, är det vinnaren av
                ett Grand Prix eller mästerskap man bettar på. Via flera av de
                stora spelbolagen har du även möjlighet till livespel. Tror du
                dig veta vem som kommer komma före vem i ett race? Då erbjuder
                några spelbolag så kallade speed rows, där du kan spela på
                vilken ordning en grupp förare ska placera sig i. Den faktiska
                positionen i racet blir då irrelevant, istället blir det en
                inbördes tävling mellan de du valt att spela på.
              </p>
              <ol className={styles.checklist}>
                <li className={styles.checklistItem}>
                  Skapa dig ordentlig med bakgrundsfakta och satsa bara pengar
                  på sånt du har koll på, förslagsvis den motorsport du gillar
                  och följer.
                </li>
                <li className={styles.checklistItem}>
                  Jämför spelsidor och hitta de spel som verkar roliga och som
                  har bäst odds.
                </li>
                <li className={styles.checklistItem}>
                  När du har hittat ett roligt spel med bra odds, klicka på
                  oddset och ange önskad insats. Klicka sedan vidare för att
                  lägga ditt spel. Kom ihåg att{' '}
                  <a
                    className={styles.checkLink}
                    href="https://www.stodlinjen.se"
                  >
                    spela ansvarsfullt
                  </a>
                </li>
                <li className={styles.checklistItem}>
                  Sen är det bara följa racet, heja på din favorit, och hoppas
                  att du får vinna pengar på ditt bet.
                </li>
              </ol>
            </section>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default Betting
