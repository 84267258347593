import React from 'react'
import SEO from '../components/Blocks/SEO'
import Betting from '../components/Pages/Betting'

function BettingPage(props) {
  return (
    <React.Fragment>
      <SEO
        title="Bästa spelbolagen för motorsport betting (2020)"
        description="(Uppdaterad) Här finns allt om betting på motorsport. Följ de enkla stegen på den här sidan för att ha chans att vinna pengar…"
      />
      <Betting />
    </React.Fragment>
  )
}

export default BettingPage
